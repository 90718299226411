<template>
  <div v-loading="loading">
    <el-form
      :model="baseInfoForm"
      status-icon
      label-position="right"
      ref="baseInfoForm"
      label-width="160px"
      class="demo-ruleForm"
      :rules="baseInfoFormRules"
    >
      <!--基本信息-->
      <div class="base-info-item">
        <h4>基本信息</h4>
        <div>
          <el-form-item label="店铺名称：">
            <el-input
              v-model="baseInfoForm.shop_name"
              class="ipt-default width"
              placeholder="请输入店铺名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="所选活动分类：">
            <el-cascader
              expand-trigger="hover"
              :options="typeOptions"
              @change="typeChange"
              :props="{ checkStrictly: true }"
              disabled
            ></el-cascader>
            <div class="typeBox">
              <el-tag
                style="background:#008080;color:#fff;margin-right:10px;margin-bottom:10px;"
                :key="index"
                v-for="(item,index) in typeChooseList"
                @close="tagClose(index)"
              >{{ item }}
              </el-tag>
            </div>
          </el-form-item>
          <el-form-item label="常用联系人姓名：" prop="link_name">
            <el-input
              v-model="baseInfoForm.link_name"
              class="ipt-default width"
              placeholder="请输入常用联系人姓名"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="常用联系人手机号：" prop="link_phone">
            <el-input
              v-model="baseInfoForm.link_phone"
              class="ipt-default width"
              placeholder="请输入常用联系人手机号"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="商户类型：" prop="shop_status">
            <div style="width:600px;">
              <el-radio v-model="baseInfoForm.shop_status" disabled :label="1">企业</el-radio>
              <el-radio v-model="baseInfoForm.shop_status" disabled :label="2">个人</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="企业营业执照：" prop="licence_img" v-if="baseInfoForm.shop_status==1">
            <div>
              <el-upload
                :action="MixinUploadApi"
                :show-file-list="false"
                :on-success="upLoadImgSuccess"
                :before-upload="upLoadImgBefore"
                disabled
              >
                <div class="uploadBox" v-if="baseInfoForm.licence_img===''">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div>上传营业执照</div>
                </div>
                <img
                  class="imgBox"
                  :src="baseInfoForm.licence_img"
                  v-if="baseInfoForm.licence_img!==''"
                  @click="clickImg(baseInfoForm.licence_img)"
                />
              </el-upload>
            </div>
          </el-form-item>
          <template v-else>
            <el-form-item label="身份证正面：" prop="front">
              <div>
                <el-upload
                  :action="MixinUploadApi"
                  :show-file-list="false"
                  :on-success="upLoadImgSuccessFront"
                  :before-upload="upLoadImgBeforeFront"
                  disabled
                >
                  <div class="uploadBox" v-if="front===''">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <div>上传身份证正面</div>
                  </div>
                  <img
                    class="imgBox"
                    :src="front"
                    v-if="front!==''"
                  />
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="身份证反面：" prop="back">
              <div>
                <el-upload
                  :action="MixinUploadApi"
                  :show-file-list="false"
                  :on-success="upLoadImgSuccessBack"
                  :before-upload="upLoadImgBeforeBack"
                  disabled
                >
                  <div class="uploadBox" v-if="back===''">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <div>上传身份证反面</div>
                  </div>
                  <img
                    class="imgBox"
                    :src="back"
                    v-if="back!==''"
                  />
                </el-upload>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="电子邮箱：" prop="link_email">
            <el-input
              v-model="baseInfoForm.link_email"
              class="ipt-default width"
              placeholder="请输入电子邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="常用联系人微信号：" prop="link_weixin">
            <el-input
              v-model="baseInfoForm.link_weixin"
              class="ipt-default width"
              placeholder="请输入常用联系人微信号"
            ></el-input>
          </el-form-item>
          <el-form-item label="常用联系人QQ：" prop="link_qq">
            <el-input
              v-model="baseInfoForm.link_qq"
              class="ipt-default width"
              placeholder="请输入常用联系人QQ"
            ></el-input>
          </el-form-item>
          <el-button
            type="primary"
            style="margin:0 auto;display:block;"
            @click="PUT_sellerEditShop"
          >保存并提交
          </el-button>
        </div>
      </div>
    </el-form>
    <el-form
      v-if="baseInfoForm.shop_status === 1"
      :model="baseInfoForm2"
      status-icon
      label-position="right"
      ref="baseInfoForm2"
      label-width="160px"
      class="demo-ruleForm"
      :rules="enterpriseRules"
    >
      <!--账户信息-->
      <div class="base-info-item">
        <h4>
          账户信息({{ baseInfoForm2.open_flag === 0 ? '未开户' : baseInfoForm2.open_flag === 1 ? '已开户' : '开户失败：' + baseInfoForm2.causeFailure }})</h4>
        <div>
          <el-form-item label="开户行所在地：" prop="bank_province_id">
            <div>
              <!-- <en-region-picker
                :api="MixinRegionApi"
                @changed="handleChangeArea"
                :default="address"
              ></en-region-picker> -->
              <address-selector :level="1" :value="address" @change="handleChangeArea"></address-selector>
            </div>
          </el-form-item>
          <el-form-item label="开户行：" prop="bank_belong">
            <!-- <el-input
              v-model="baseInfoForm2.bank_belong"
              class="ipt-default width"
              placeholder="请输入开户行"
            ></el-input> -->
            <el-select v-model="baseInfoForm2.bank_belong" @change="handleBank">
              <el-option
                v-for="item in bank_no_list" :key="item.id" :value="item.id" :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户行支行：" prop="bank_name">
            <el-input
              v-model="baseInfoForm2.bank_name"
              class="ipt-default width"
              placeholder="请输入开户行支行"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账户名：" prop="bank_account_name">
            <el-input
              v-model="baseInfoForm2.bank_account_name"
              class="ipt-default width"
              placeholder="请输入账户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="账号：" prop="bank_number">
            <el-input
              v-model="baseInfoForm2.bank_number"
              class="ipt-default width"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务联系人：" prop="financial_link_name">
            <el-input
              v-model="baseInfoForm2.financial_link_name"
              class="ipt-default width"
              placeholder="请输入财务联系人,以便联系确认"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务联系人电话：" prop="financial_link_phone">
            <el-input
              v-model="baseInfoForm2.financial_link_phone"
              class="ipt-default width"
              placeholder="请输入财务联系人电话"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="企业名称：" prop="company_name">
            <el-input
              v-model="baseInfoForm2.company_name"
              class="ipt-default width"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="社会信用代码：" prop="organization_code">
            <el-input
              v-model="baseInfoForm2.organization_code"
              class="ipt-default width"
              placeholder="请输入社会信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="经营者/法人姓名：" prop="legal_name">
            <div>
              <el-input
                v-model="baseInfoForm2.legal_name"
                class="ipt-default width"
                placeholder="请输入经营者/法人姓名"
              ></el-input>
              <span class="explain">说明：个体户需录入营业执照上经营者姓名；企业需录入营业执照上法人姓名</span>
            </div>
          </el-form-item> -->
          <el-button
            type="primary"
            style="margin:0 auto;display:block;"
            @click="GET_sellerShopsOpenAccount"
          >{{ baseInfoForm2.open_flag === 0 || baseInfoForm2.open_flag === 2 ? '保存并申请开户' : '修改开户信息' }}
          </el-button>
        </div>
      </div>
    </el-form>

    <!-- 放大图片 -->
    <big-img v-if="showImg" @clickit="viewImg" :imgSrc="imgSrc"></big-img>

    <el-form
      v-if="baseInfoForm.shop_status === 2"
      :model="baseInfoForm2"
      status-icon
      label-position="right"
      ref="baseInfoForm2"
      label-width="160px"
      class="demo-ruleForm"
      :rules="personalRules"
    >
      <!--账户信息-->
      <div class="base-info-item">
        <h4>
          账户信息({{ baseInfoForm2.open_flag === 0 ? '未开户' : baseInfoForm2.open_flag === 1 ? '已开户' : '开户失败：' + baseInfoForm2.causeFailure }})</h4>
        <div>
          <el-form-item label="开户行所在地：" prop="bank_province_id">
            <div>
              <!-- <en-region-picker
                :api="MixinRegionApi"
                @changed="handleChangeArea"
                :default="address"
              ></en-region-picker> -->
              <address-selector :level="1" :value="address" @change="handleChangeArea"></address-selector>
            </div>
          </el-form-item>
          <el-form-item label="开户行：" prop="bank_belong">
            <!-- <el-input
              v-model="baseInfoForm2.bank_belong"
              class="ipt-default width"
              placeholder="请输入开户行"
            ></el-input> -->
            <el-select v-model="baseInfoForm2.bank_belong" @change="handleBank">
              <el-option
                v-for="item in bank_no_list" :key="item.id" :value="item.id" :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户行支行：" prop="bank_name">
            <el-input
              v-model="baseInfoForm2.bank_name"
              class="ipt-default width"
              placeholder="请输入开户行支行"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账户名：" prop="bank_account_name">
            <el-input
              v-model="baseInfoForm2.bank_account_name"
              class="ipt-default width"
              placeholder="请输入账户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="账号：" prop="bank_number">
            <el-input
              v-model="baseInfoForm2.bank_number"
              class="ipt-default width"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务联系人：" prop="financial_link_name">
            <el-input
              v-model="baseInfoForm2.financial_link_name"
              class="ipt-default width"
              placeholder="请输入财务联系人,以便联系确认"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务联系人电话”" prop="financial_link_phone">
            <el-input
              v-model="baseInfoForm2.financial_link_phone"
              class="ipt-default width"
              placeholder="请输入财务联系人电话"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="企业名称：" prop="company_name">
            <el-input
              v-model="baseInfoForm2.company_name"
              class="ipt-default width"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="社会信用代码：" prop="organization_code">
            <el-input
              v-model="baseInfoForm2.organization_code"
              class="ipt-default width"
              placeholder="请输入社会信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="经营者/法人姓名：" prop="legal_name">
            <div>
              <el-input
                v-model="baseInfoForm2.legal_name"
                class="ipt-default width"
                placeholder="请输入经营者/法人姓名"
              ></el-input>
              <span class="explain">说明：个体户需录入营业执照上经营者姓名；企业需录入营业执照上法人姓名</span>
            </div>
          </el-form-item> -->
          <el-form-item label="身份证号：" prop="legal_id">
            <div>
              <el-input
                v-model="baseInfoForm2.legal_id"
                class="ipt-default width"
                placeholder="请输入身份证号"
              ></el-input>
              <span class="explain">说明：企业提供法人本人的身份证号，个体户提供营业执照上经营者本人的身份证号</span>
            </div>
          </el-form-item>
          <el-button
            type="primary"
            style="margin:0 auto;display:block;"
            @click="GET_sellerShopsOpenAccount"
          >{{ baseInfoForm2.open_flag === 0 || baseInfoForm2.open_flag === 2 ? '保存并申请开户' : '修改开户信息' }}
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import * as API_shopBaseInfo from "@/api/shopBaseInfo";
import * as API_activityType from "@/api/activityType";
import Storage from '@/utils/storage';
import AddressSelector from "@/components-new/address-selector/address-selector";
import BigImage from '@/components-new/big-Image/big-image'

export default {
  components: {
    AddressSelector,
    'big-img': BigImage
  },
  data() {
    const frontCheck = (rule, value, callback) => {
      if (!this.front && this.front !== 0) {
        return callback(new Error('请上传身份证正面'))
      } else {
        callback()
      }
    }
    const backCheck = (rule, value, callback) => {
      if (!this.back && this.back !== 0) {
        return callback(new Error('请上传身份证反面'))
      } else {
        callback()
      }
    }
    return {
      /**  加载中。。。 */
      loading: false,

      /**  基本信息提交表单 */
      baseInfoForm: {
        shop_name: "",
        category_list: [],
        link_name: "",
        link_phone: "",
        shop_status: "",
        licence_img: "",
        link_email: "",
        link_weixin: "",
        link_qq: "",
      },
      /** 身份证正反 */
      front: "",
      back: "",

      /** 开户行编号列表 */
      bank_no_list: [],

      /**  基本信息提交表单校验 */
      baseInfoFormRules: {
        link_name: [
          {
            required: true,
            message: "请输入常用联系人姓名",
            trigger: "blur"
          },
        ],
        link_phone: [
          {
            required: true,
            message: "请输入常用联系人手机号",
            trigger: "blur",
          },
        ],
        shop_status: [
          {
            required: true,
            message: "请选择商户类型",
            trigger: "blur"
          },
        ],
        licence_img: [
          {
            required: true,
            message: "请上传企业营业执照",
            trigger: "blur"
          },
        ],
        front: [
          {
            required: true,
            validator: frontCheck,
            trigger: "blur"
          },
        ],
        back: [
          {
            required: true,
            validator: backCheck,
            trigger: "blur"
          },
        ],
        // link_email: [
        //   {
        //     required: true,
        //     message: "请输入电子邮箱",
        //     trigger: "blur"
        //   },
        // ],
        // link_weixin: [
        //   {
        //     required: true,
        //     message: "请输入常用联系人微信号",
        //     trigger: "blur",
        //   },
        // ],
        // link_qq: [
        //   {
        //     required: true,
        //     message: "请输入常用联系人QQ",
        //     trigger: "blur"
        //   },
        // ],
      },

      /**  店铺开户信息 */
      baseInfoForm2: {
        bank_province_id: "",
        bank_city_id: "",
        bank_province: "",
        bank_city: "",
        bank_belong: "",
        bankTypeNo: '',
        bank_name: "",
        bank_account_name: "",
        bank_number: "",
        financial_link_name: "",
        financial_link_phone: "",
        company_name: "",
        organization_code: "",
        legal_name: "",
        legal_id: "",
      },

      /**  店铺企业开户信息校验 */
      enterpriseRules: {
        bank_province_id: [
          {
            required: true,
            message: "请输入开户行所在地",
            trigger: "blur"
          },
        ],
        bank_belong: [
          {
            required: true,
            message: "请输入开户行",
            trigger: "blur"
          },
        ],
        bank_name: [
          {
            required: true,
            message: "请输入开户行支行",
            trigger: "blur"
          },
        ],
        bank_account_name: [
          {
            required: true,
            message: "请输入银行账户名",
            trigger: "blur"
          },
        ],
        bank_number: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur"
          },
        ],
        // legal_id: [
        //   {
        //     required: true,
        //     message: "请输入身份证号",
        //     trigger: "blur"
        //   },
        // ]
      },

      personalRules: {
        bank_province_id: [
          {
            required: true,
            message: "请输入开户行所在地",
            trigger: "blur"
          },
        ],
        bank_belong: [
          {
            required: true,
            message: "请输入开户行",
            trigger: "blur"
          },
        ],
        bank_name: [
          {
            required: true,
            message: "请输入开户行支行",
            trigger: "blur"
          },
        ],
        bank_account_name: [
          {
            required: true,
            message: "请输入银行账户名",
            trigger: "blur"
          },
        ],
        bank_number: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur"
          },
        ],
        legal_id: [
          {
            required: true,
            message: "请输入身份证号",
            trigger: "blur"
          },
        ]
      },

      /**  所有分类列表 */
      typeOptions: [],
      typeList: [],

      /**  选中分类列表 */
      typeChooseList: [],

      /**  地址回显 */
      address: [],

      showImg: false,
      imgSrc: ''
    };
  },
  mounted() {
    /**  获取店铺信息 */
    this.GET_sellerShops();

    /**  获得店铺开户信息 */
    this.GET_sellerShopsAccount();

    /**  获取活动分类列表 */
    this.GET_activeTypeList();

    this.getBankNo();
  },
  activated() {
  },
  methods: {
    /**  获取所有活动分类列表 */
    GET_activeTypeList() {
      API_activityType.sellerGoodsCategory(0).then((res) => {
        // console.log(res);
        this.typeOptions = [];
        this.typeList = [];
        res.forEach((item1, index1) => {
          var jsonParent = {};
          var jsonParentList = item1;
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          jsonParentList.children = [];
          API_activityType.sellerGoodsCategory(item1.category_id).then(
            (res2) => {
              res2.forEach((item2, index2) => {
                var jsonChild = {};
                jsonChild.value = item2.category_id;
                jsonChild.label = item2.name;
                children.push(jsonChild);
                jsonParentList.children.push(item2);
              });
              jsonParent.children = children;
              this.typeOptions.push(jsonParent);
              this.typeList.push(jsonParentList);
            }
          );
        });
      });
    },

    /** 放大图片 */
    clickImg(item) {
      this.showImg = true;
      // 获取当前图片地址
      this.imgSrc = item
    },
    viewImg() {
      this.showImg = false;
    },

    handleBank(e) {
      this.baseInfoForm2.bank_type_no = e
      this.bank_no_list.forEach(item => {
        if (item.id === e) {
          this.baseInfoForm2.bank_belong = item.name
        }
      })
    },

    /** 获取开户行编号 */
    getBankNo() {
      API_shopBaseInfo.getBankNo().then(res => {
        this.bank_no_list = res
      })
    },

    /**  获取店铺信息 */
    GET_sellerShops() {
      API_shopBaseInfo.sellerShops().then((res) => {
        this.baseInfoForm = res;
        this.front = JSON.parse(this.baseInfoForm.legal_img).front;
        this.back = JSON.parse(this.baseInfoForm.legal_img).back;
        Storage.setItem('seller_shop', res);
        // 选择活动分类回显
        this.baseInfoForm.category_list.forEach((item) => {
          if (item.cat_second_name) {
            this.typeChooseList.push(
              item.cat_first_name + "-" + item.cat_second_name
            );
          } else {
            this.typeChooseList.push(item.cat_first_name)
          }
        });
      });
    },

    /**  修改店铺信息 */
    PUT_sellerEditShop() {
      // console.log(this.baseInfoForm);
      this.$refs.baseInfoForm.validate((valid) => {
        if (valid) {
          API_shopBaseInfo.sellerEditShop(this.baseInfoForm).then((res) => {
            this.$message.success("提交成功！");
            console.log(res);
          });
        } else {
          this.$message.error("请填写完整基本信息表单！");
          return false;
        }
      });
    },

    /**  获得店铺开户信息 */
    GET_sellerShopsAccount() {
      API_shopBaseInfo.sellerShopsAccount().then((res) => {
        this.baseInfoForm2 = res;
        // console.log(res);
        // 地址回显
        this.address = [
          res.bank_province_id,
          res.bank_city_id
        ].filter(id => !!id)
      });
    },

    /**  店铺开户设置 */
    GET_sellerShopsOpenAccount() {
      this.$refs.baseInfoForm2.validate((valid) => {
        if (valid) {
          API_shopBaseInfo.sellerShopsOpenAccount(this.baseInfoForm2).then(
            (res) => {
              this.$message.success("提交成功！");
              this.GET_sellerShops();
              this.GET_sellerShopsAccount();
            }
          );
        } else {
          this.$message.error("请填写完整开户信息表单！");
          return false;
        }
      });
    },

    /**  上传图片前校验 */
    upLoadImgBefore(file) {
      const isImg = file.type.indexOf("image") > -1;
      if (!isImg) {
        this.$message.error("只能上传图片");
      }
      return isImg;
    },

    /**  上传图片 */
    upLoadImgSuccess(res, file) {
      this.baseInfoForm.licence_img = file.response.url;
    },

    /**  上传图片前校验 */
    upLoadImgBeforeFront(file) {
      const isImg = file.type.indexOf("image") > -1;
      if (!isImg) {
        this.$message.error("只能上传图片");
      }
      return isImg;
    },

    /**  上传图片 */
    upLoadImgSuccessFront(res, file) {
      this.front = file.response.url;
      this.baseInfoForm.legal_img = JSON.stringify({ front: this.front, back: this.back });
    },
    /**  上传图片前校验 */
    upLoadImgBeforeBack(file) {
      const isImg = file.type.indexOf("image") > -1;
      if (!isImg) {
        this.$message.error("只能上传图片");
      }
      return isImg;
    },

    /**  上传图片 */
    upLoadImgSuccessBack(res, file) {
      this.back = file.response.url;
      this.baseInfoForm.legal_img = JSON.stringify({ front: this.front, back: this.back });
    },

    /**  选择区域 */
    handleChangeArea(val) {
      const fields = ['province', 'city'];

      fields.forEach(field => {
        this.baseInfoForm2[`bank_${field}_id`] = 0;
        this.baseInfoForm2[`bank_${field}`] = '';
      });

      val.forEach((item, index) => {
        this.baseInfoForm2[`bank_${fields[index]}_id`] = item.id;
        this.baseInfoForm2[`bank_${fields[index]}`] = item.local_name;
      })
    },

    /**  选择分类 */
    typeChange(val) {
      var typeStr = "";
      var typeJson = {};
      console.log(this.typeList);
      this.typeList.forEach((item1) => {
        if (item1.category_id === val[0]) {
          typeStr = item1.name;
          typeJson.cat_first_id = item1.category_id;
          typeJson.cat_first_name = item1.name;
          typeJson.commission_rate = item1.commission_rate;
          typeJson.shop_id = this.baseInfoForm.shop_id;
          item1.children.forEach((item2) => {
            if (item2.category_id === val[1]) {
              typeStr += "-" + item2.name;
              typeJson.cat_second_id = item2.category_id;
              typeJson.cat_second_name = item2.name;
              typeJson.commission_rate = item2.commission_rate;
            }
          });
        }
      });
      var isNew = true;
      this.typeChooseList.forEach((item) => {
        if (item === typeStr) {
          isNew = false;
        }
      });
      if (isNew) {
        this.typeChooseList.push(typeStr);
        this.baseInfoForm.category_list.push(typeJson);
      }
      console.log(this.baseInfoForm.category_list);
    },

    /**  删除分类 */
    tagClose(index) {
      this.typeChooseList.splice(index, 1);
      this.baseInfoForm.category_list.splice(index, 1);
    },
  },
};
</script>

<style type='text/scss' lang='scss' scoped>
/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

/*平铺*/
div.base-info-item > div {
  margin-left: 5%;
}

div.base-info-item {
  h4 {
    margin-bottom: 10px;
    padding: 0 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    font-weight: bold;
    color: #333;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
  }
}

/deep/ .el-form-item {
  width: auto !important;

  .el-form-item__label {
    width: 160px !important;
  }
}

/* 上传区域图标 */
.uploadBox {
  width: 110px;
  height: 110px;
  border: 1px dashed rgba(221, 221, 221, 1);
  border-radius: 4px;
  background: rgba(247, 250, 250, 1);

  i {
    display: block;
    margin: 26px auto 12px;
    color: #999;
    font-size: 33px;
  }

  div {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #999;
  }
}

/* 图片盒子 */
.imgBox {
  width: 110px;
}

/* 表单说明 */
.explain {
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #888;
  margin-left: 30px;
  margin-top: 10px;
}

/* 选中分类标签盒子 */
.typeBox {
  width: 600px;
  border: 2px dashed rgba(221, 221, 221, 1);
  border-radius: 8px;
  padding: 24px 24px 14px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.width {
  width: 400px;
}
</style>

<style type='text/scss' lang='scss'>
.el-form-item__label {
  word-break: break-all;
}
</style>
