/**
 * 店铺基础信息相关API
 */

import request from '@/utils/request'

/**
 * 获取店铺信息
 * @param
 */
export function sellerShops() {
  return request({
    url: '/seller/shops',
    method: 'get',
    loading: false
  })
}

/**
 * 获取开户行编号
 * @param
 */
export function getBankNo() {
  return request({
    url: '/seller/shops/getBankNo',
    method: 'get'
  })
}

/**
 * 修改店铺信息
 * @param params
 */
export function sellerEditShop(params) {
  return request({
    url: '/seller/shops/editShop',
    method: 'put',
    loading: false,
    headers: { "Content-Type": "application/json" },
    data: params
  })
}

/**
 * 获得店铺开户信息
 * @param
 */
export function sellerShopsAccount() {
  return request({
    url: '/seller/shops/account',
    method: 'get',
    loading: false
  })
}

/**
 * 店铺开户设置
 * @param params
 */
export function sellerShopsOpenAccount(params) {
  return request({
    url: '/seller/shops/openAccount',
    method: 'get',
    loading: false,
    params
  })
}
